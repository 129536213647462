const siteUrl = process.env.NODE_ENV === 'production' ? 'https://www.changefirst.com' : 'http://localhost:8000';
const apiUrl = process.env.NODE_ENV === 'production' ? 'https://admin.changefirst.com' : 'http://localhost:1337';

module.exports = {
    siteMetadata: {
        title: 'Changefirst',
        apiURL: '',
        siteUrl: siteUrl,
    },
    plugins: [
        'gatsby-plugin-sass',
        `gatsby-plugin-remove-trailing-slashes`,
        'gatsby-plugin-image',
        {
            resolve: "gatsby-plugin-google-tagmanager",
            options: {
                id: "GTM-NQTFVFR",

                // Include GTM in development.
                //
                // Defaults to false meaning GTM will only be loaded in production.
                includeInDevelopment: false,

                // datalayer to be set before GTM is loaded
                // should be an object or a function that is executed in the browser
                //
                // Defaults to null
                defaultDataLayer: { platform: "gatsby" },

                // Specify optional GTM environment details.
                // gtmAuth: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_AUTH_STRING",
                // gtmPreview: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_PREVIEW_NAME",
                //dataLayerName: "YOUR_DATA_LAYER_NAME",

                // Name of the event that is triggered
                // on every Gatsby route change.
                //
                // Defaults to gatsby-route-change
                routeChangeEventName: "changefirst-route-change",
                // Defaults to false
                enableWebVitalsTracking: true,
                // Defaults to https://www.googletagmanager.com
                // selfHostedOrigin: "YOUR_SELF_HOSTED_ORIGIN",
            },
        },
        {
            resolve: `gatsby-plugin-google-gtag`,
            options: {
                // You can add multiple tracking ids and a pageview event will be fired for all of them.
                trackingIds: [
                    "UA-2600490-8", // Google Analytics / GA
                    "G-5S9Q4BKHS8"
                    // Google Ads / Adwords / AW
                    //"AW-CONVERSION_ID",
                    // Marketing Platform advertising products (Display & Video 360, Search Ads 360, and Campaign Manager)
                    // "DC-FLOODIGHT_ID",
                ],
                // This object gets passed directly to the gtag config command
                // This config will be shared across all trackingIds
                gtagConfig: {
                    // optimize_id: "OPT_CONTAINER_ID",
                    anonymize_ip: true,
                    cookie_expires: 0,
                },
                // This object is used for configuration specific to this plugin
                pluginConfig: {
                    // Puts tracking script in the head instead of the body
                    head: true,
                    // Setting this parameter is also optional
                    respectDNT: true,
                    // Avoids sending pageview hits from custom paths
                    exclude: ["/preview/**"],
                },
            },
        },
        'gatsby-plugin-react-helmet',
        {
            resolve:'gatsby-plugin-sitemap',
            options: {
                query:`{
                    site {
                        siteMetadata {
                            siteUrl
                        }
                    }
                    allSitePage {
                        nodes {
                            path
                            context {
                                noIndex
                            }
                        }
                    }
                }`,
                resolveSiteUrl: () => siteUrl,
                resolvePages: (data) => {
                    let filtered =  data.allSitePage.nodes.filter(x => {
                        if(!x.context.noIndex) {
                            return x;
                        }
                    })
                    return filtered
                },
                serialize: (page, tools) => {
                    return {
                        url: siteUrl + page.path,
                        changefreq: 'daily',
                        priority: 0.7,
                    };
                },
            }
        },
        'gatsby-transformer-remark',
        'gatsby-plugin-sharp',
        'gatsby-transformer-sharp',
        {
            resolve: 'gatsby-source-filesystem',
                options: {
                    name: 'images',
                    path: './src/assets/',
                },
            __key: 'images',
        },
        {
            resolve: `gatsby-source-strapi`,
            options: {
                apiURL: apiUrl,
                queryLimit: 1000,
                collectionTypes: ['pages'],
                singleTypes: ['navbar', 'footer', 'header'],
            },
        },
    ],
};
